<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <vb-headers-tag :data="{ title: 'Les inscrits au Newslettre' }" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-top card-top-danger">
          <div class="card-header py-0">
            <vb-headers-card-header-tabbed :data="{ title: 'Liste des inscrits' }" />
          </div>
            <div class="card-body">
              <div class="row">
                <!--
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-xl-0 mb-3" :class="$style.item">
                        <span :class="$style.icon">
                          <i class="fe fe-home" />
                        </span>
                        <div :class="$style.desc">
                          <span :class="$style.title">Block Title</span>
                          <p>Waiting for review</p>
                        </div>
                        <div :class="$style.line" class="bg-success" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-xl-0 mb-3" :class="$style.item">
                        <span :class="$style.icon">
                          <i class="fe fe-command" />
                        </span>
                        <div :class="$style.desc">
                          <span :class="$style.title">Block Title</span>
                          <p>Waiting for review</p>
                        </div>
                        <div :class="$style.line" class="bg-primary" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-xl-0 mb-3" :class="$style.item">
                        <span :class="$style.icon">
                          <i class="fe fe-star" />
                        </span>
                        <div :class="$style.desc">
                          <span :class="$style.title">Block Title</span>
                          <p>Waiting for review</p>
                        </div>
                        <div :class="$style.line" class="bg-warning" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-xl-0 mb-3" :class="$style.item">
                        <span :class="$style.icon">
                          <i class="fe fe-database" />
                        </span>
                        <div :class="$style.desc">
                          <span :class="$style.title">Block Title</span>
                          <p>Waiting for review</p>
                        </div>
                        <div :class="$style.line" class="bg-gray-5" />
                      </div>
                    </div>                    
                  </div>                  
                </div>
                -->
                <div class="col-md-12">
                  <inscriptioon-table />
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VbHeadersTag from '@/components/widgets/Headers/Tag'
import VbHeadersCardHeaderTabbed from '@/components/widgets/Headers/CardHeader4'
import inscriptioonTable from '@/components/widgets/TablesAntd/newlettre'

export default {
  name: 'VbPublications',
  components: {
    VbHeadersTag,
    VbHeadersCardHeaderTabbed,
    inscriptioonTable,
  },
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
